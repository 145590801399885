@import "./nprogress.css";
@import "./tailwind.css";

*{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.005rem;
  outline: none;
}
@media (min-width: 1536px) {
  .container {
    max-width: 1360px !important;
  }
}
.button-hamburger{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.blue-t-algo{
  color: #2E6CC7;
}
.bg-blue-algo{
  background-color: #b4dcff1a;
}
.bg-lime-light2{
  background-color: #eeff41;
}
.round-bottom{
  border-radius: 0 0 4rem 4rem;
  z-index: 1;
  position: relative;
}
.round-bottom2{
  border-radius: 0 0 3rem 3rem;
  position: relative;
}
@media (max-width: 768px) {
  .round-bottom, .round-bottom2{
    border-radius: 0 0 1.25rem 1.25rem;
  }
}
/* Navbar link items */
.navbar-item {
  @apply block w-max mt-4 mb-3 font-medium text-black text-base mr-0 xl:mr-4 xl:inline-block xl:mt-2 xl:mb-0 hover:opacity-100;
  &.active {
    @apply text-sky-600;
  }
  &.course {
    @apply text-black mt-1 lg:mb-0 mb-3 mr-6;
  }
}

/* Client Logo */
.client-wrapper img{
  max-height: 85px;
}
@media (max-width: 768px) {
  .client-wrapper img{
    max-height: 65px;
  }
}

/* Language icon */
.langitem {
  @apply m-1 flex-initial flex items-center py-2 px-4 rounded-md bg-white
}

/* Page footer */
footer p, footer a {
  @apply text-white font-medium
}
footer .grid p {
  @apply font-semibold opacity-100 mb-3
}
footer .grid a {
  @apply leading-loose block w-min whitespace-nowrap hover:opacity-100
}

/* Plan Comparison Table */
table th {
  @apply tracking-wider font-semibold uppercase text-sm px-4 py-4
}
table td {
  @apply bg-slate-100 text-sm px-4 py-4 text-center
}
table td:first-child{
  @apply !text-left font-semibold
}

/* Cookie Table */
table.cookie-table {
  thead {
    th {
      @apply text-xs text-center border-x-1 border-t-1 border-slate-100
    }
  }

  tbody {
    td {
      &:first-child {
        @apply !text-center
      }
      
      @apply text-xs text-left
    }
  }

  @apply w-full
}

/* Price Tag Divider */
.pricetag hr {
  border-top-width: 1.8px;
  @apply my-3 
}

/* Swiper carousel nav buttons */
.swiper-button-next, .swiper-button-prev {
  @apply text-gray-500 h-auto
}
.swiper-button-next::after, 
.swiper-button-prev::after {
  @apply text-lg
}

/* Privacy Policy section */
.pp-section {
  @apply max-w-4xl bg-white lg:px-20 lg:py-16 md:px-14 md:py-12 px-8 py-6 rounded-2xl mx-5 md:mx-10 lg:mx-auto lg:-mt-80 -mt-48
}
.pp-section h1 {
  @apply text-4xl font-semibold mb-4
}
.pp-section h5 {
  @apply text-lime-dark font-semibold text-15p my-2.5 mb-5
}
.pp-section b {
  @apply font-semibold  
}

/* Terms And Conditions */
.tnc-section {
  p {
    @apply mb-3;
  }
  h3 {
    @apply text-xl font-semibold my-4;
  }
}

/* Form */
form {
  @apply md:grid grid-cols-2 gap-4 text-sm;
}
form label {
  @apply block font-medium mb-1
}
form i {
  @apply text-red-500 font-medium
}
form input,
form textarea {
  @apply block w-full py-1.5 px-3 rounded-sm h-45p border border-gray-200 hover:border-[#b3b3b3];
  &.error {
    @apply border-red-300 hover:border-red-500;
  }
}
form button {
  @apply bg-gray-400 rounded px-8 py-3 text-white font-medium hover:opacity-80
}
form > div {
  @apply mb-4 md:mb-0
}
form input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 2px;
  border-radius: 4px;
  @apply p-0 cursor-pointer mr-2 flex-shrink-0 appearance-none border border-gray-300 checked:bg-blue-600 checked:border-transparent focus:outline-none
}
form input[type="checkbox"] + label {
  @apply cursor-pointer w-auto h-auto font-normal
}
form input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
}
form .select-container {
  input {
    @apply h-auto;
  }
  [class$="control"] {
    @apply shadow-none;
  }
  &.error {
    [class$="control"] {
      @apply border-red-300 hover:border-red-500;
    }
  }
}

/* Button / Link base decorator */
button, a.button {
  @apply rounded px-8 py-3 font-medium hover:opacity-80 text-sm disabled:bg-gray-400 disabled:cursor-default disabled:hover:opacity-100;
}
a.button {
  @apply py-2 lg:rounded-full;
  &.full-round {
    @apply rounded-full;
  }
  &.button-lg {
    @apply py-3;
  }
}

/* NumberStep circle */
.c-number {
  line-height: 5px;
  letter-spacing: -0.63px;
  padding: 20px;
}
.c-number.bg-lime-dark {
  padding: 14px;
  font-size: 17px;
}

/* Container padding */
.container-padding{
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 768px) {
  .container-padding{
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* Green Pricing section */
.bg-green-pricing{
  background-image: url('/images/pricing-sec-img.png');
}

@media (max-width: 768px) {
  .bg-green-pricing{
    background-image: none !important;
  }
}
/* Peep background image */
.bg-peep {
  background-image: url(/images/peep-bg.svg);
  background-position: center top;
}
@media (max-width: 1024px) {
  .bg-peep {
    background-size: 0px;
  }
}
@screen lg {
  .bg-peep {
    background-repeat: no-repeat;
    background-size: auto 80%;
  }
}

/* Sweetalert style */
button.swal2-confirm.swal2-styled {
  @apply bg-blue-semidark min-w-[80px];
  &:focus {
    box-shadow: 0 0 0 3px rgb(4 69 167 / 50%);
  }
}

/* Markdown Styling */
.desc-html {
  @apply font-normal text-[#232323] opacity-80;

  & > * {
    @apply mb-2;
  }

  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
  h5 {
    @apply text-lg;
  }
  h6 {
    @apply font-medium;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
  li {
    @apply ml-5;
  }
  pre {
    @apply bg-gray-300 p-3 px-5 rounded-md text-sm;
  }
  table {
    td, th {
      @apply border-1;
    }
    th {
      @apply pt-2;
    }
  }
  a {
    @apply text-blue underline;
  }
}

.course-navigation {
  @apply flex-grow lg:flex items-center lg:static absolute top-20 left-0 bg-white lg:p-0 px-8 py-3.5 w-full lg:rounded-none rounded-2xl lg:shadow-none shadow-lg;
  
  transition: opacity 0.25s ease-out, visibility 0.25s ease-out;
  opacity: 0;
  visibility: hidden;
  
  &.active {
    visibility: visible;
    opacity: 1 !important;
  }

  @screen lg {
    visibility: visible;
    opacity: 1 !important;
  }
}